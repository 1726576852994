<template>
    <v-dialog v-model="showingTagModal" width="700" scrollable persistent minHeight="1200">
        <v-form ref="tagForm" autocomplete="off" @submit.prevent="createTag">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    {{editTagId ? 'Editar Tag' : 'Crear Tag'}}
                </v-card-title>
                <v-card-text style="max-height: 500px">
                    <v-row class="mt-2">
                        <v-col cols="12" md="12" class="mb-1">
                            <v-text-field
                            ref="tagName"
                            v-model="name"
                            outlined
                            dense
                            autocomplete="off"
                            :rules="[v => !!v || 'Campo requerido']"
                            hide-details="auto"
                            label="Nombre del Tag"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <span 
                    class="error--text error-msg"
                    v-if="error_msg"
                    >{{ error_msg }}</span>

                </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close()">
                        CANCELAR
                    </v-btn>

                    <v-btn color="primary" type="submit">
                        {{editTagId ? 'EDITAR TAG' : 'CREAR TAG'}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
    data: () => {
        return {
            name: '',
            editTagId: null,
            error_msg: null
        }
    },

    computed: {
            getUserRole() { return this.$store.getters['session/getUserRole']() },
            getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
            getAllCompaniesList() { return this.$store.getters['session/getAllCompaniesList']() },
        },

    methods: {
        open(item_to_edit) {
            item_to_edit
                    ? this.setItemToEdit(item_to_edit)
                    : this.showingTagModal = true
        },

        close() {
            this.$refs.tagForm.reset()
            this.editTagId = null
            this.showingTagModal = false
            this.error_msg = null
        },

        setItemToEdit(item_to_edit) {
            const item = item_to_edit
            if(item) {
                this.editTagId = item.id

                this.name = item.name
                this.company = this.getAllCompaniesList.find(el => el.id === item.company_id)
            }
            this.showingTagModal = true
        },

        async createTag() {
            if(this.$refs.tagForm.validate()) {

                const form = {
                    name: this.name,
                    company_id: this.getSelectedCompany
                }

                try {
                    const response = this.editTagId
                                        ? await this.$api.updateTag(this.editTagId, form)
                                        : await this.$api.createTag(form)
                    this.$emit('reload-tag-list')
                    this.close()
                } catch(e) {
                    console.log(e)
                    if (e?.response?.status === 403) {
                        console.log(e.response.data)
                        this.error_msg = e?.response?.data?.message
                    }
                }
            }
        }
    },

    setup() {
        const showingTagModal = ref(false)

        return {
            showingTagModal
        }
    }
}

</script>

<style>
.error-msg {
    font-size: 15px;
}
</style>