<template>
    <div>
        <v-card>
            <v-card-title>Tags</v-card-title>
            <TagsList></TagsList>
        </v-card>
    </div>
</template>

<script>
import TagsList from '@/components/Tags/TagsList';

export default {
    components: {
        TagsList
    }
}

</script>