<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          @keyup="handleSearch"
          placeholder="Buscar por nombre de tag"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3 px-2 no-uppercase"
            @click="$refs.tag_form_modal.open()"
          >
          <v-icon>{{ icons.mdiTagPlus }}</v-icon>
          <span>Crear Tag</span>
          </v-btn>
        </div>
      </v-card-text>
        <!-- List -->
      <v-data-table
      :headers="tableColumns"
      :page="current_page"
      :items="tagListTable"
      :server-items-length="totalTagListTable"
      :loading="loading"
      @update:page="changingTagsPage($event)"
      @update:items-per-page="changingTagsItemsPerPage($event)"
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No hay
              <strong>Tags</strong>
              disponibles para esta búsqueda
            </v-list-item-title>
          </v-list-item>
        </template>

        <!--Tag-->
        <template #[`item.name`]="{item}">
          <v-btn
          class="no-uppercase tag-container px-1 py-0"
          text
          max-height="26px"
          >
            <v-icon
            class="mr-1"
            dense
            small
            >{{ icons.mdiTag }}</v-icon>
            <span>{{item.name}}</span>
          </v-btn>
        </template>

        <!--Total contacts-->
        <template #[`item.contacts`]="{item}">
          <v-btn
          class="no-uppercase total-contacts-container pa-2"
          text
          max-height="26px"
          @click="$refs.tag_contact_info.open(item.id)"
          >
            <v-icon
            class="mr-1"
            dense
            color="rgb(130, 15, 236)"
            >{{ icons.mdiAccountGroup }}</v-icon>
            <span>{{item.contacts}}</span>
          </v-btn>
        </template>

        <!--Edit-->
        <template #[`item.edit`]="{item}">
          <v-btn
          icon
          color="primary"
          @click="$refs.tag_form_modal.open(item)"
          >
            <v-icon>
              {{ icons.mdiSquareEditOutline }}
            </v-icon>
          </v-btn>
        </template>

        <!--Delete-->
        <template #[`item.delete`]="{item}">
          <v-btn
            icon
            @click="$refs.delete_tag_modal.open(item)"
          >
            <v-icon class="error--text">
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <DeleteTagModal
      ref="delete_tag_modal"
      @reload-tag-list="getTags()"
      />

      <TagFormModal
      ref="tag_form_modal"
      @reload-tag-list="getTags()"
      />

      <TagContactInfo
      ref="tag_contact_info"
      />

    </v-card>
  </div>
</template>

<script>
const debounce = require('debounce');
import DeleteTagModal from '@/components/Tags/DeleteTagsModal'
import TagFormModal from '@/components/Tags/TagFormModal'
import TagContactInfo from '@/components/Tags/TagContactInfo';

import { ref } from '@vue/composition-api';
import {
  mdiTagPlus,
  mdiTag,
  mdiAccountGroup,
  mdiSquareEditOutline,
  mdiDeleteOutline
} from '@mdi/js'

export default {
  data: () => {
    return {
      current_page: 1,
      page_size: 10,
    }
  },

  components: {
    DeleteTagModal,
    TagFormModal,
    TagContactInfo
  },

  mounted() {
    if(this.getSelectedCompany) {
      this.getTags()
    }
  },
  methods: {
    handleSearch: debounce(function (e) {
      this.current_page = 1
      this.getTags()
    }, 400),

    changingTagsPage(e) {
      this.current_page = e
      this.getTags()
    },

    changingTagsItemsPerPage(e) {
      this.page_size = e
      this.getTags()
    },


    async getTags() {

      this.loading = true

      try {
        const query = {
          company_id: this.getSelectedCompany,
          page_size: this.page_size,
          page: this.current_page,
          search: this.searchQuery,
        }

        const response = await this.$api.getTags(query)
        this.tagListTable = response.results
        this.totalTagListTable = response.count
      } catch(error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  },

  computed: {
      getUserRole() { return this.$store.getters['session/getUserRole']() },
      getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
  },

  watch: {
    getSelectedCompany(newValue) {
        if(newValue) {
            this.current_page = 1
            this.page_size = 10
            this.getTags()
        }
    }
  },

  setup(){
    const totalTagListTable = ref(1);
    const tagListTable = ref([]);

    const tableColumns = [
      { text: 'Tag', value: 'name', align: 'center', sortable: false },
      { text: 'Total de Contactos', value: 'contacts', align: 'center', sortable: false },
      { text: 'Editar', value: 'edit', align: 'center', sortable: false },
      { text: 'Eliminar', value: 'delete', align: 'center', sortable: false },
    ];

      const loading = ref(false);
      const searchQuery = ref('');

      return {
        tableColumns,
        loading,
        searchQuery,
        totalTagListTable,
        tagListTable,
        icons: {
          mdiTagPlus,
          mdiTag,
          mdiAccountGroup,
          mdiSquareEditOutline,
          mdiDeleteOutline
        }
      }
    }
}
</script>

<style scoped>
.no-uppercase {
  text-transform: unset !important;
}

.tag-container {
  color: white;
  background-color: rgb(120, 120, 120);
  font-size: 14px;
  transition: 0.3s;
}

.tag-container::before {
  color: transparent
}
.tag-container:hover {
  background-color: rgb(15, 15, 15);
}

.total-contacts-container {
  min-width: 0 !important;
  background-color: rgba(137, 43, 226, 0.2);
  color:rgba(137, 43, 226, 0.9);
  border: 1.5px solid rgb(130, 15, 236);
}
</style>
