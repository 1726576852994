import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Buscar por nombre de tag","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 me-3 px-2 no-uppercase",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.tag_form_modal.open()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiTagPlus))]),_c('span',[_vm._v("Crear Tag")])],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"page":_vm.current_page,"items":_vm.tagListTable,"server-items-length":_vm.totalTagListTable,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},on:{"update:page":function($event){return _vm.changingTagsPage($event)},"update:items-per-page":function($event){return _vm.changingTagsItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No hay "),_c('strong',[_vm._v("Tags")]),_vm._v(" disponibles para esta búsqueda ")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"no-uppercase tag-container px-1 py-0",attrs:{"text":"","max-height":"26px"}},[_c(VIcon,{staticClass:"mr-1",attrs:{"dense":"","small":""}},[_vm._v(_vm._s(_vm.icons.mdiTag))]),_c('span',[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.contacts",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"no-uppercase total-contacts-container pa-2",attrs:{"text":"","max-height":"26px"},on:{"click":function($event){return _vm.$refs.tag_contact_info.open(item.id)}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"dense":"","color":"rgb(130, 15, 236)"}},[_vm._v(_vm._s(_vm.icons.mdiAccountGroup))]),_c('span',[_vm._v(_vm._s(item.contacts))])],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$refs.tag_form_modal.open(item)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiSquareEditOutline)+" ")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.delete_tag_modal.open(item)}}},[_c(VIcon,{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)}),_c('DeleteTagModal',{ref:"delete_tag_modal",on:{"reload-tag-list":function($event){return _vm.getTags()}}}),_c('TagFormModal',{ref:"tag_form_modal",on:{"reload-tag-list":function($event){return _vm.getTags()}}}),_c('TagContactInfo',{ref:"tag_contact_info"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }