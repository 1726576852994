<template>
    <v-dialog v-model="showingModal" width="40%">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Contacto: {{contact.name }}
            </v-card-title>
            <v-card-subtitle 
            v-if="contact.correct === false"
            class="pt-8 no-datos-span"
            >
                Contacto dado de baja
            </v-card-subtitle>
            <v-card-subtitle 
            v-if="contact.correct === true"
            class="pt-8 no-datos-span"
            >
                Contacto confirmado
            </v-card-subtitle>
            <v-card-subtitle 
            v-if="!contact.link1 && !contact.link2 && !contact.email && !contact.dato1 && !contact.dato2 && !contact.dato3 && !contact.dato4 && !contact.dato5 && !contact.dato6 && !contact.dato7 && !contact.dato8 && !contact.dato9 && !contact.dato10"
            class="pt-8 no-datos-span"
            >
                No se ha ingresado ningún dato extra.
            </v-card-subtitle>
            <v-card-text>
                <v-list>
                    <div>
                        <v-list-item-content v-if="contact.link1">
                            <v-list-item-title>Link 1:</v-list-item-title>
                            <v-list-item-subtitle>{{contact.link1}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="contact.link2">
                            <v-list-item-title>Link 2:</v-list-item-title>
                            <v-list-item-subtitle>{{contact.link2}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="contact.email">
                            <v-list-item-title>Email:</v-list-item-title>
                            <v-list-item-subtitle>{{contact.email}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="contact.dato1">
                            <v-list-item-title>Dato 1:</v-list-item-title>
                            <v-list-item-subtitle>{{contact.dato1}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="contact.dato2">
                            <v-list-item-title>Dato 2:</v-list-item-title>
                            <v-list-item-subtitle>{{contact.dato2}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="contact.dato3">
                            <v-list-item-title>Dato 3:</v-list-item-title>
                            <v-list-item-subtitle>{{contact.dato3}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="contact.dato4">
                            <v-list-item-title>Dato 4:</v-list-item-title>
                            <v-list-item-subtitle>{{contact.dato4}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="contact.dato5">
                            <v-list-item-title>Dato 5:</v-list-item-title>
                            <v-list-item-subtitle>{{contact.dato5}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="contact.dato6">
                            <v-list-item-title>Dato 6:</v-list-item-title>
                            <v-list-item-subtitle>{{contact.dato6}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="contact.dato7">
                            <v-list-item-title>Dato 7:</v-list-item-title>
                            <v-list-item-subtitle>{{contact.dato7}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="contact.dato8">
                            <v-list-item-title>Dato 8:</v-list-item-title>
                            <v-list-item-subtitle>{{contact.dato8}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="contact.dato9">
                            <v-list-item-title>Dato 9:</v-list-item-title>
                            <v-list-item-subtitle>{{contact.dato9}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="contact.dato10">
                            <v-list-item-title>Dato 10:</v-list-item-title>
                            <v-list-item-subtitle>{{contact.dato10}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </div>
                </v-list>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data:() => {
        return {
            showingModal: false,
            contact: {},
        }
    },
    methods: {
        open(item) {
            this.contact = item
            this.showingModal = true
        },
        close() {
            this.contact = {}
            this.showingModal = false
        }
    }
}
</script>

<style>
.no-datos-span {
    font-size: 16px !important;
}
</style>
