<template>
    <v-dialog
        v-model="showingDeleteTagModal"
        width="auto"
    >
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                ¿Estás seguro de eliminar el tag '{{ tag.name }}'?
            </v-card-title>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                color="primary" 
                text 
                @click="close()"
                >
                    CANCELAR
                </v-btn>         
                <v-btn
                color="error"
                @click="deleteTag()"
                >
                    Eliminar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    data: () => {
        return {
            showingDeleteTagModal: false,
            tag: {}
        }
    },

    methods: {
        open(tag) {
            this.showingDeleteTagModal = true
            this.tag = tag
        },

        close() {
            this.showingDeleteTagModal = false
        },

        async deleteTag() {
            try {
                const response = await this.$api.deleteTag(this.tag.id)

                this.$emit('reload-tag-list')
                this.close()
            } catch(e) {
                console.log(e)
            }
        }
    }
}
</script>