import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"700","scrollable":"","persistent":"","minHeight":"1200"},model:{value:(_vm.showingTagModal),callback:function ($$v) {_vm.showingTagModal=$$v},expression:"showingTagModal"}},[_c(VForm,{ref:"tagForm",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.createTag.apply(null, arguments)}}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.editTagId ? 'Editar Tag' : 'Crear Tag')+" ")]),_c(VCardText,{staticStyle:{"max-height":"500px"}},[_c(VRow,{staticClass:"mt-2"},[_c(VCol,{staticClass:"mb-1",attrs:{"cols":"12","md":"12"}},[_c(VTextField,{ref:"tagName",attrs:{"outlined":"","dense":"","autocomplete":"off","rules":[function (v) { return !!v || 'Campo requerido'; }],"hide-details":"auto","label":"Nombre del Tag"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),(_vm.error_msg)?_c('span',{staticClass:"error--text error-msg"},[_vm._v(_vm._s(_vm.error_msg))]):_vm._e()],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CANCELAR ")]),_c(VBtn,{attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.editTagId ? 'EDITAR TAG' : 'CREAR TAG')+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }