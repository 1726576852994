import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"800"},model:{value:(_vm.showing_modal),callback:function ($$v) {_vm.showing_modal=$$v},expression:"showing_modal"}},[_c(VCard,[_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.contactsListTable,"page":_vm.current_page,"server-items-length":_vm.totalContactsListTable,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},on:{"update:page":function($event){return _vm.changingContactsPage($event)},"update:items-per-page":function($event){return _vm.changingContactsItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No hay "),_c('strong',[_vm._v("Contactos")]),_vm._v(" disponibles para esta búsqueda ")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"no-uppercase",attrs:{"text":""},on:{"click":function($event){return _vm.$refs.contacts_details_modal.open(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags),function(tag){return _c(VBtn,{staticClass:"no-uppercase tag-container my-1",attrs:{"text":"","max-height":"26px"}},[_c(VIcon,{staticClass:"mr-1",attrs:{"dense":"","small":""}},[_vm._v(_vm._s(_vm.icons.mdiTag))]),_c('span',[_vm._v(_vm._s(tag.name))])],1)})}}],null,true)})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CERRAR ")])],1),_c('ContactsDetailsModal',{ref:"contacts_details_modal"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }